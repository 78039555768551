












































































































































import { Component, Inject, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { namespace as authNs } from "@/store/auth";
import { SwaggerDoc } from "@/components";
import { getConfig } from "@/config";

@Component({
  components: {
    SwaggerDoc,
  },
})
export default class APIDocs extends Vue {
  @Getter("jwtToken", { namespace: authNs }) jwtToken!: string;

  private backendUrl: string = getConfig().backendUrl;

  get apiKeyUrl(): string {
    return `${this.backendUrl}/jwt/swagger?group=APIKEY`;
  }

  get jwtUrl(): string {
    return `${this.backendUrl}/jwt/swagger`;
  }

  @Inject("onSuccessfulCopy")
  private onSuccessfulCopy!: () => void;
}
